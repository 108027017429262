import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface ModalState {
    isModalOpen: boolean;
}

const initialState: ModalState = {
    isModalOpen: false,
};

const modalSlice = createSlice({
    name: 'modal',
    initialState,
    reducers: {
        openModal: (state) => {
            state.isModalOpen = true;
        },
        closeModal: (state) => {
            state.isModalOpen = false;
        },
    },
});

export const { openModal, closeModal } = modalSlice.actions;
export const modalState = (state: { modal: ModalState }) => state.modal.isModalOpen;
export const modalReducer = modalSlice.reducer;
