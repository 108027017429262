import { configureStore } from '@reduxjs/toolkit';
import { sidePanelReducer } from 'one-js-web/src/redux/sidePanelDialog';
import { titleReducer } from 'one-js-web/src/redux/Title';
import { modalReducer } from 'one-js-web/src/redux/Logout';

const store = configureStore({
    reducer: {
        sidePanel: sidePanelReducer,
        title: titleReducer,
        modal: modalReducer,
    },
});

export default store;

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
