// eslint-disable-next-line import/no-self-import
import Axios from 'axios';
import reduxStore from 'one-js-web/src/reduxStore';
import { openModal } from 'one-js-web/src/redux/Logout';
import { BASE_URL } from 'one-js-web/src/config/index';

// eslint-disable-next-line import/prefer-default-export
export const axios = Axios.create({
    baseURL: BASE_URL,
    headers: {
        'Content-Type': 'application/json',
    },
});

axios.interceptors.request.use((config) => {
    const token = localStorage.getItem('authToken');
    config.headers.Authorization = token ? `Bearer ${token}` : '';
    return config;
});

axios.interceptors.response.use(
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    (response) => response.data,
    (error: { response: { status: number } }) => {
        if (error !== null && error.response.status === 401) {
            reduxStore.dispatch(openModal());
        }
        return Promise.reject(error);
    },
);
